var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe-widget" }, [
    _vm.frameUrl
      ? _c("iframe", {
          style: _vm.frameHeight ? `height: ${_vm.frameHeight}px` : "",
          attrs: {
            src: _vm.frameUrl,
            id: _vm.frameId,
            title: "Iframe Widget",
            allow: "fullscreen; clipboard-write",
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }